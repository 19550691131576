import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchThermostatTimeHistoryAction,
  fetchTimeHistoryFailure,
  fetchTimeHistoryLoading,
  fetchTimeHistorySuccess
} from '../../actions';
import { getSelectedDatesKey } from "../../chart-utils.js";
import { LazyLoader } from '../../components/lazy-loader';
import ReactGridLayout from 'react-grid-layout';
import TemperatureChart from "./thermostat-temp-chart";

const MEASURE_NAMES = [
  { id: "temperature", names: ["ActualTemperature", "HeatSetpoint", "CoolSetpoint", "DischargeTemperature", "ReturnTemperature"] },
];

const ThermostatTimeHistory = ({ layoutWidth, selectedDates, onSetErrorMsg }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchDeviceTimeHistory = (dates, measureNamesGroup) => {
    const { id: measureNamesGroupId, names: channelAliases } = measureNamesGroup;

    dispatch(fetchTimeHistoryLoading(measureNamesGroupId));

    const onSuccess = resp => {
      onSetErrorMsg("");

      dispatch(fetchTimeHistorySuccess(getSelectedDatesKey(selectedDates), {
        id: measureNamesGroupId,
        results: resp
      }));
    };

    const onError = err => {
      onSetErrorMsg(err?.data?.message || "Failed to fetch metrics");
      dispatch(fetchTimeHistoryFailure(measureNamesGroupId, [err?.data?.message]));
    };

    const onFinally = () => {};

    fetchThermostatTimeHistoryAction(
      id,
      {
        selectedDates: dates,
        channelAliases,
      },
      onSuccess,
      onError,
      onFinally
    );
  };

  const handleFetchMetrics = useCallback((measureNameGroup) => {
    fetchDeviceTimeHistory(selectedDates, measureNameGroup);
  }, [selectedDates]);

  const chartList = [
    {
      Component: TemperatureChart,
      measureNameGroupId: "temperature",
      rowHeight: 400,
    },
  ];

  const chartRowRenderer = ({ key, index, style, isScrolling }) => {
    const { Component, measureNameGroupId, extraProps = {} } = chartList[index];
    const measureNameGroup = MEASURE_NAMES.find(it => it.id === measureNameGroupId);

    return (
      <LazyLoader key={key} style={style} data-griditem-key={index}>
        <Component
          selectedDates={selectedDates}
          measureNameGroup={measureNameGroup}
          onFetchMetrics={handleFetchMetrics}
          isScrolling={isScrolling}
          {...extraProps} />
      </LazyLoader>
    );
  };

  const reactGridLayoutObj = chartList.map((chartListItem, idx) => {
    const layoutObj = {
      i: idx.toString(),
      x: 0,
      y: idx,
    };

    if (document.documentElement.clientWidth >= 480) {
      return {
        ...layoutObj,
        h: 11,
        w: 23,
      };
    } else {
      return {
        ...layoutObj,
        h: 12,
        w: 21,
      };
    }
  });

  const charts = chartList.map((_, idx) => chartRowRenderer({
    key: idx.toString(),
    index: idx,
    style: {},
  }));

  return (
    <ReactGridLayout
      width={layoutWidth + 40}
      isDraggable={false}
      isResizable={false}
      containerPadding={[0, 0]}
      useCSSTransforms={false}
      margin={[8, 8]}
      cols={24}
      rowHeight={30}
      layout={reactGridLayoutObj}
      isBounded={false}>
      {charts}
    </ReactGridLayout>
  );
};

export default ThermostatTimeHistory;
