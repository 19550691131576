import { utcToZonedTime, format } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

const LINE_CHART_COLORS = [
  {
    border: '#003f5c',
    background: '#003f5c',
  },
  {
    border: '#dd5182',
    background: '#dd5182',
  },
  {
    border: '#4bc0c0',
    background: '#4bc0c0',
  },
  {
    border: '#ffcd56',
    background: '#ffcd56',
  },
  {
    border: '#42b47d',
    background: '#42b47d',
  }
];

export const formatInTimeZone = (date, fmt, tz) => {
  const utcDate = utcToZonedTime(date, tz);
  return format(utcDate, fmt, { timeZone: tz });
};

export const DEFAULT_LINE_OPTIONS = {
  spanGaps: true,
  normalized: true,
  animation: false,
  borderWidth: 1,
  pointRadius: 0,
  maintainAspectRatio: document.documentElement.clientWidth < 480,
  aspectRatio: 1,
  responsive: true,
  scales: {
    x: {
      type: 'time',
      time: {
        adapters: {
          date: {
            locale: enUS,
          },
        },
        parser: (value) => format(value, 'yyyy-MM-dd HH:mm:ss'),
        unit: 'minute',
        displayFormats: {
          minute: 'HH:mm',
        },
      },
      title: {
        display: true,
        text: 'Date',
      },
    },
    y: {
      min: 0,
      max: 100,
    },
  },
  plugins: {
    legend: {},
    title: {
      display: true,
      text: 'Default Text',
    },
  },
};

export const GAUGE_CHART_OPTIONS = {
  maintainAspectRatio: false,
  rotation: 270,
  circumference: 180,
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    gaugeNeedle: {

    },
  },
  cutout: '90%',
};

export const DEFAULT_GAUGE_DATA = {
  labels: [-100, -80, -50, -25],
  datasets: [{
    label: 'WiFi Signal Strength',
    data: [36, 54, 45, 45],
    needleValue: 0,
    backgroundColor: ['#de425b', '#488f31', '#488f31', '#488f31'],
  }],
};

export const getLineChartOptions = (titleText, yUnit = '', yMin = 0, yMax = 100, yTicksStepSize = 25) => ({
  ...DEFAULT_LINE_OPTIONS,
  plugins: {
    ...DEFAULT_LINE_OPTIONS.plugins,
    title: {
      ...DEFAULT_LINE_OPTIONS.plugins.title,
      text: titleText,
    },
  },
  scales: {
    ...DEFAULT_LINE_OPTIONS.scales,
    y: {
      ...DEFAULT_LINE_OPTIONS.scales.y,
      min: yMin,
      max: yMax,
      ticks: {
        callback: (val) => `${val} ${yUnit || ''}`,
        stepSize: yTicksStepSize,
      },
    },
  },
});

export const getLineChartData = (chartTitles, seriesData) => {
  if (seriesData.length === 0) {
    return { labels: [], datasets: [] };
  }

  const labels = seriesData[0].map((item) => item[0]);
  const datasets = seriesData.map((item, idx) => ({
    label: chartTitles[idx],
    data: item.map((it) => it[1]),
    borderColor: LINE_CHART_COLORS[idx].border,
    backgroundColor: LINE_CHART_COLORS[idx].background,
  }));

  return { labels, datasets };
};

export const getGaugeNeedleValue = (min, max, value) => {
  const range = max - min;
  return (value * (180 / range)) + ((-1 * min) / range) * 180;
};

export const getDataFromLabels = (min, max, labels) => {
  const diffs = [];

  for (let i = 0; i < labels.length; i += 1) {
    if (i !== labels.length - 1) diffs.push(labels[i + 1] - labels[i]);
    else diffs.push(min - labels[i]);
  }

  return diffs.map((diff) => (diff / (max - min)) * 180).slice(0, diffs.length - 1);
};

export const getGaugeData = (label, value, min, max, labels, backgroundColor) => {
  const needleValue = getGaugeNeedleValue(min, max, value);
  const data = getDataFromLabels(min, max, labels);

  return {
    ...DEFAULT_GAUGE_DATA,
    labels: labels || DEFAULT_GAUGE_DATA.labels,
    datasets: [
      {
        ...DEFAULT_GAUGE_DATA.datasets[0],
        data,
        needleValue,
        label,
        backgroundColor,
      },
    ],
  };
};

export const processData = (timestamps, metrics, dataKey, dataType, isSeries) => {
  const parseNumber = dataType === 'int' ? parseInt : parseFloat;

  if (!metrics || !Object.keys(metrics).length) {
    return isSeries ? [] : 0;
  }

  if (isSeries) {
    return timestamps
      .filter((it, index) => {
        let metricValue = metrics[dataKey]?.[index];

        if (metricValue !== null && typeof metricValue === 'object') {
          metricValue = metricValue.value;
        }

        const value = parseNumber(metricValue);
        return value !== undefined && !Number.isNaN(value);
      })
      .map((timestamp, index) => {
        let metricValue = metrics[dataKey][index];

        if (metricValue !== null && typeof metricValue === 'object') {
          metricValue = metricValue.value;
        }

        const value = parseNumber(metricValue);
        return [new Date(parseInt(timestamp, 10)), value];
      });
  }

  return parseNumber(metrics[dataKey][metrics[dataKey].length - 1]) || 0;
};

export const getSelectedDatesKey = (selectedDates) => `${selectedDates[0].getTime()}-${selectedDates[1].getTime()}`;
